import {Component, ElementRef} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'Praxis';

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FEFEF8';
    this.elementRef.nativeElement.ownerDocument.body.style.color = '#535359';
    this.elementRef.nativeElement.ownerDocument.body.style.fontFamily = 'MS Reference Sans Serif';
  }

}
