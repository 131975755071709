import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'us',
  templateUrl: './us.component.html',
  styleUrls: ['./us.component.sass']
})
export class UsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
