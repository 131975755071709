import { NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ContactComponent} from "./contact/contact.component";
import {SupplyComponent} from "./supply/supply.component";
import {ProcedureComponent} from "./procedure/procedure.component";
import {UsComponent} from "./us/us.component";
import {HomeComponent} from "./home/home.component";
import {ImpressumComponent} from "./impressum/impressum.component";

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'supply', component: SupplyComponent },
  { path: 'procedure', component: ProcedureComponent },
  { path: 'us', component: UsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
